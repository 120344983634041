<style lang="scss">
.epaper {
  .portfolio-box {
    margin-top: 50px;
    margin-bottom: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }

    .bild-box {
      @media (max-width: 992px) {
        margin-bottom: 20px;
      }
    }
  }
  .tabelle-headline {
    margin-top: 40px;
    strong {
    }
  }
  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
}
</style>

<template>
  <div class="content epaper container">
    <h1>E-Paper</h1>
    <p class="intro-txt"><strong>Das E-Paper der Kleinen Zeitung bringt die Printausgabe auf Tablet, Smartphone und PC</strong> – und das bereits am Vorabend ab 23:30 Uhr. Erreichen Sie mit unseren innovativen E-Paper-Werbeformen auch unsere Digital-Abonnenten und profitieren Sie von der hohen Wiederbesuchsrate.</p>

    <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-epaper.jpg" alt="E-Paper ZW" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Zwischenseite</h2>
          <ul class="content-ul">
            <li>Erreichung unserer Digital-Abonnenten durch Fixplatzierung</li>
            <li>Hohe Interaktions- und Wiederbesuchsrate von Abonnenten</li>
            <li>Vielfältige Gestaltungsmöglichkeiten im großflächigen Format</li>
            <li>gezielte Ausspielung am Ende Ihres Regionalteils möglich</li>
          </ul>

          <CollapsibleButton link="e-paper" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum E-Paper </CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="e-paper">
      <div class="row">
        <div class="col-lg-12">
          <h3>Produktdaten</h3>
          <p>Zwischenseiten werden auf allen Plattformen - Android-App, iOS-App und Browserversion - angezeigt.</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <img src="@/assets/img/img-content/digital/e-paper-zw.jpg" alt="E-Paper ZW" class="img-fluid" />
        </div>
      </div>
      <h3>E-Paper klassisch</h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="tabelle-box">
            <div class="row head-row hide-small">
              <div class="col-lg-4 text-align-left">Überregional</div>
              <div class="col-lg-4 text-align-right">SO-MI</div>
              <div class="col-lg-4 text-align-right">DO-SA</div>
            </div>
            <div class="row content-row">
              <div class="col-6 text-align-left visible-small">Überregional</div>
              <div class="col-lg-4 col-6 text-align-left text-right-small">Gesamtausgabe</div>
              <div class="col-6 text-align-left visible-small">SO-MI</div>
              <div class="col-lg-4 col-6 text-align-right">1.850,00</div>
              <div class="col-6 text-align-left visible-small">DO-SA</div>
              <div class="col-lg-4 col-6 text-align-right">1.400,00</div>
            </div>
            <div class="row content-row">
              <div class="col-6 text-align-left visible-small">Überregional</div>
              <div class="col-lg-4 col-6 text-align-left text-right-small">Steiermark</div>
              <div class="col-6 text-align-left visible-small">SO-MI</div>
              <div class="col-lg-4 col-6 text-align-right">1.250,00</div>
              <div class="col-6 text-align-left visible-small">DO-SA</div>
              <div class="col-lg-4 col-6 text-align-right">940,00</div>
            </div>
            <div class="row content-row">
              <div class="col-6 text-align-left visible-small">Überregional</div>
              <div class="col-lg-4 col-6 text-align-left text-right-small">Kärnten</div>
              <div class="col-6 text-align-left visible-small">SO-MI</div>
              <div class="col-lg-4 col-6 text-align-right">600,00</div>
              <div class="col-6 text-align-left visible-small">DO-SA</div>
              <div class="col-lg-4 col-6 text-align-right">400,00</div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="tabelle-box">
            <div class="row head-row hide-small">
              <div class="col-lg-4 text-align-left">Pro Region</div>
              <div class="col-lg-4 text-align-right">SO–MI</div>
              <div class="col-lg-4 text-align-right">DO-SA</div>
            </div>
            <div class="row content-row">
              <div class="col-6 text-align-left visible-small">Überregional</div>
              <div class="col-lg-4 col-6 text-align-left text-right-small">Graz</div>
              <div class="col-6 text-align-left visible-small">SO–MI</div>
              <div class="col-lg-4 col-6 text-align-right">470,00</div>
              <div class="col-6 text-align-left visible-small">DO-SA</div>
              <div class="col-lg-4 col-6 text-align-right">360,00</div>
            </div>
            <div class="row content-row">
              <div class="col-6 text-align-left visible-small">Überregional</div>
              <div class="col-lg-4 col-6 text-align-left text-right-small">Klagenfurt</div>
              <div class="col-6 text-align-left visible-small">SO–MI</div>
              <div class="col-lg-4 col-6 text-align-right">300,00</div>
              <div class="col-6 text-align-left visible-small">DO-SA</div>
              <div class="col-lg-4 col-6 text-align-right">200,00</div>
            </div>
            <div class="row content-row">
              <div class="col-6 text-align-left visible-small">Überregional</div>
              <div class="col-lg-4 col-6 text-align-left text-right-small">Alle anderen Regionen</div>
              <div class="col-6 text-align-left visible-small">SO–MI</div>
              <div class="col-lg-4 col-6 text-align-right">160,00</div>
              <div class="col-6 text-align-left visible-small">DO-SA</div>
              <div class="col-lg-4 col-6 text-align-right">120,00</div>
            </div>
          </div>
        </div>
      </div>

      <div class="info-txt-preis">Datenanlieferung: Seite im Hochformat (768 x 954 Px) und Querformat (1024 x 698 Px) + Vorschaubild (105 x 145 Px).</div>
      <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
    </Collapsible>
    <!-- <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/d-medium-rectangle.jpg" alt="Medium Rectangle" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>Medium Rectangle</h2>
          <p>Der Klassiker unter den digitalen Werbeformaten: Mit einer Fixplatzierung auf der E-Paper-Startseite sorgen Sie für exklusive Aufmerksamkeit bei unseren digitalen Abonnenten.</p>
          <div class="preis-box">
            <span>Tarif ab:<strong>35,00 TKP</strong></span>
          </div>
          <CollapsibleButton link="medium-rectangle" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zum Medium Rectangle</CollapsibleButton>
        </div>
      </div>
    </div>
    <Collapsible link="medium-rectangle">
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center">
          <img src="@/assets/img/img-content/digital/bsp-medium-rectangle.png" alt="Bsp. Medium Rectangle" class="img-fluid" />
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="tabelle-box">
            <div class="row head-row">
              <div class="col-lg-6 col-6 text-align-left">PLATZIERUNG</div>
              <div class="col-lg-6 col-6 text-align-right">TARIF</div>
            </div>
            <div class="row content-row">
              <div class="col-lg-6 col-6 text-align-left">Auf der Startseite</div>
              <div class="col-lg-6 col-6 text-align-right">35,00*</div>
            </div>
            <div class="info-txt-tabelle">*Preisangaben in TKP (Preis für 1.000 Sichtkontakte).</div>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20% UST.</div>
        </div>
      </div>
      <div class="hinweis-box text-center"><strong>SONDERANGEBOT</strong> // Ein Tag Fixplatzierung für nur <strong>EURO 196,00</strong></div>
    </Collapsible> -->
    <!-- <div class="portfolio-box">
      <div class="row">
        <div class="col-lg-6">
          <div class="bild-box">
            <img src="@/assets/img/img-content/digital/bsp-epaper-verlinkung.png" alt="E-Paper Verlinkung" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6">
          <h2>E-Paper-Printverlinkung</h2>
          <p><strong>Erwecken Sie Ihr Printangebot zum Leben!</strong> Mit einer direkten Verlinkung machen Sie Ihr Angebot interaktiv. Verlinken Sie Ihr Printsujet direkt mit Ihrer Website. Der Aufpreis für diesen Service beträgt 2 % der Kosten Ihrer Printschaltung (minimal 5,00 und maximal 100,00).</p>
          <div class="preis-box">
            <span>Tarif ab:<strong>5,00</strong></span>
          </div>
          !--<CollapsibleButton link="e-paper-verlinkung" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Alle Informationen zur E-Paper-Printverlinkung</CollapsibleButton>
        </div>
      </div>
    </div> -->
    <!--<Collapsible link="e-paper-verlinkung">
      e-paper verlinkung
    </Collapsible>-->
    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/digital/E-Paper-11-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif E-Paper
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>187 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  name: 'EPaper',
  components: {
    CollapsibleButton,
    Collapsible,
  },
});
</script>
